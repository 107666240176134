import "./application.css";
import { useReducer, useState } from "react";
import emailjs from "@emailjs/browser";

const Application = () => {
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [isFileSelected, setIsFileSelected] = useState(false);
  // const [fileSizeError, setFileSizeError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const initialFormState = {};

  const reducer = (state, action) => {
    return { ...state, [action.name]: action.value };
  };

  const [state, dispatch] = useReducer(reducer, initialFormState);

  // const uploadChangeHandler = (e) => {
  //   setSelectedFile(e.target.files[0]);
  //   setIsFileSelected(true);
  // };

  // const uploadHandleSubmission = (e) => {
  //   e.preventDefault();

  //   if (!selectedFile) {
  //     //error - upload the file
  //   }
  //   // if the size > 500KB , show error
  //   if (selectedFile?.size > 500 * 1024) {
  //     setFileSizeError(true);
  //   } else {
  //     setFileSizeError(false);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);
    // all filed validation, if all good then send email

    emailjs
      .sendForm(
        "service_xjspb89",
        "application_form",
        e.target,
        "dUXyFoO7FxwFDV3SJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsFormSubmitted(true);
        },
        (error) => {
          console.log(error.text);
          setEmailError(true);
        }
      );
  };

  if (emailError) {
    return (
      <div className="email-error">
        <p className=" email-message-title">
          Something went wrong. Please retry!
        </p>
        <br />
        <p className="email-message">
          If this issue persists, please report to&nbsp;
          <strong>info@fintekpoint.com</strong>
        </p>
      </div>
    );
  }

  if (isFormSubmitted && !emailError) {
    return (
      <div className="email-notification-container">
        <p className="email-message-title email-success">
          Application Received!
        </p>
        <br />
        <br />
        <p className="email-message">Dear {state.fname},</p>
        <br />
        <p className="email-message">
          You will be receiving an email and phone call for scheduling a
          personal assessment within 2 business days.
          <br />
          <br />
          Thanks for applying @ FINTEKPOINT
        </p>
      </div>
    );
  }

  return (
    <div className="application-container">
      <h1 className="application-title">
        Save your spot! <br /> Apply now
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="application-form">
          <label htmlFor="fname">First name *</label>
          <input
            type="text"
            id="fname"
            name="fname"
            value={state.fname}
            required={true}
            placeholder="First name"
            onChange={(e) => {
              dispatch(e.target);
            }}
          />

          <label htmlFor="lname">Last name *</label>
          <input
            type="text"
            id="lname"
            name="lname"
            value={state.lname}
            placeholder="Last name"
            required={true}
            onChange={(e) => {
              dispatch(e.target);
            }}
          />

          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={state.email}
            placeholder="Email"
            required={true}
            onChange={(e) => {
              dispatch(e.target);
            }}
          />

          <label htmlFor="phone">Phone *</label>
          <input
            type="number"
            id="phone"
            name="phone"
            value={state.phone}
            placeholder="Phone number"
            required={true}
            onChange={(e) => {
              dispatch(e.target);
            }}
          />

          <label htmlFor="education">
            What is your highest level of education? *
          </label>
          <input
            type="select"
            id="education"
            name="education"
            value={state.education}
            placeholder="Bachelors degree"
            required={true}
            onChange={(e) => {
              dispatch(e.target);
            }}
          />

          <label htmlFor="graduationYear">Year of graduation *</label>
          <select
            id="graduationYear"
            name="graduationYear"
            // menuPortalTarget={"document.body"}
            // menuPlacement={"auto"}
            // menuPosition={"fixed"}
            required={true}
            onChange={(e) => {
              dispatch(e.target);
            }}
          >
            {["2023", "2022", "2021", "2020", "2019", "2018", "2017"].map(
              (year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              )
            )}
          </select>
          <label htmlFor="aggregate">Aggregate of marks(CGPA)*</label>
          <input
            type="number"
            id="aggregate"
            name="aggregate"
            value={state.aggregate}
            placeholder="Aggregate of marks"
            required={true}
            onChange={(e) => {
              dispatch(e.target);
            }}
          />
          <label htmlFor="city">Current Location *</label>
          <input
            type="text"
            id="city"
            name="city"
            value={state.city}
            placeholder="City"
            required={true}
            onChange={(e) => {
              dispatch(e.target);
            }}
          />

          <p className="radio">
            Do you have prior work experience after graduation? *
          </p>
          <div className="radio">
            <input
              type="radio"
              id="priorExperienceYes"
              name="priorExperience"
              value="Yes"
              required={true}
              checked={state.priorExperience === "Yes"}
              onChange={(e) => {
                dispatch(e.target);
              }}
            />
            <label htmlFor="priorExperienceYes">Yes </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="radio"
              id="priorExperienceNo"
              name="priorExperience"
              value="No"
              required={true}
              checked={state.priorExperience === "No"}
              onChange={(e) => {
                console.log(e.target.value);
                dispatch(e.target);
              }}
            />
            <label htmlFor="priorExperienceYes">No </label>
          </div>

          <label htmlFor="employmentStatus">Employment status *</label>
          <select
            id="employmentStatus"
            name="employmentStatus"
            required={true}
            onChange={(e) => {
              dispatch(e.target);
            }}
          >
            <option value="Looking for job">Looking for job</option>
            <option value="Full time employemnt - 40 hours">
              Full time employemnt - 40 hours
            </option>
            <option value="Part time employment - upto 20 hours">
              Part time employment - upto 20 hours
            </option>
          </select>

          <label htmlFor="lname">LinkedIn URL (optional)</label>
          <input
            type="url"
            id="linkedinUrl"
            name="linkedinUrl"
            value={state.linkedinUrl}
            placeholder="https://www.linkedin.com/in/username"
            onChange={(e) => {
              dispatch(e.target);
            }}
          />

          {/* <label htmlFor="lname">Upload Resume (optional) </label>
          <input
            type="file"
            id="resume"
            accept=".doc,.docx,.pdf"
            name="resume"
            onChange={uploadChangeHandler}
          />
          {fileSizeError && (
            <p className="file-error"> File size should be less than 500 KB</p>
          )}

          <button className="upload-button" onClick={uploadHandleSubmission}>
            Upload Resume
          </button>
          */}
          <input
            className="submit-button"
            type="submit"
            value="Submit Application"
          />
        </div>
      </form>
    </div>
  );
};

export default Application;
