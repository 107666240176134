import "./nav-header.css";
import logo from "../../images/fp1.svg";
import { Link } from "react-router-dom";
function NavHeader() {
  return (
    <div className="nav-header">
      {/* <img className="logo" src={logo} alt="Fintek Point" /> */}
      <div className="logo-text">
        <span className="logo-part-title">FINTEK</span>POINT
      </div>
      <button className="apply-now">
        <Link to="/apply-now">
          <strong>Apply Now</strong>
        </Link>
      </button>
    </div>
  );
}

export default NavHeader;
