import phone from "../../images/phone.png";
import email from "../../images/email.png";

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <p className="footer-title">
          <span className="footer-part-title">FINTEK</span>POINT
        </p>
        <h5 className="footer-mission">
          On a mission to help the iGen to succeed in the IT industry with risk
          free training.
        </h5>
        <p className="footer-address">
          <strong>USA:</strong> 7330 Gallagher Dr, Suite 137,
          <br />
          Edina, MN - 55435 <br />
          Phone : +1 763 777 1075
        </p>
        <p className="footer-address">
          <strong>India:</strong>
          <br /> S1, 2nd floor, #3 Arunachalam Road,
          <br />
          Saligramam, Chennai – 600 093
        </p>
        <p className="footer-address">
          218, Nethaji Rd, Manjakuppam, Cuddalore, <br /> Tamil Nadu - 607001
        </p>
        <div className="footer-contact">
          <div className="phone">
            {/* <img className="phone-icon" src={phone} /> */}
            {/* <a
              style={{
                color: "white",
              }}
              href={"tel:" + "+916134322717"}
            > */}
            +91 63743 22717 / 99424 44717 / 04142-214717
            {/* </a> */}
          </div>
          <div className="email">
            {/* <img className="email-icon" src={email} /> */}
            <a style={{ color: "white" }} href="mailto: info@fintekpoint.com">
              info@fintekpoint.com
            </a>
          </div>
        </div>
        <hr />
        <p className="footer-copyright">&#169; 2023 Fintek Point</p>
      </div>
    </div>
  );
};
