import "./App.css";
import NavHeader from "./compoents/nav-header/nav-header";
import { Link } from "react-router-dom";

import {
  faChalkboardUser,
  faCompass,
  faHandshakeAngle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Footer } from "./compoents/footer/footer";
import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   [
  //     { tile: "web-development-training", button: "download_webdev" },
  //     { tile: "rpa-training", button: "download_rpa" },
  //   ].forEach((item) => {
  //     document
  //       .getElementById(item.tile)
  //       ?.addEventListener("mouseenter", function () {
  //         document.getElementById(item.button).style.display = "block";
  //       });

  //     document
  //       .getElementById(item.tile)
  //       ?.addEventListener("mouseleave", function () {
  //         document.getElementById(item.button).style.display = "none";
  //       });
  //   });
  // }, []);

  return (
    <div className="App">
      <header className="App-header">
        <NavHeader />
      </header>
      <div className="container">
        {/* <div className="attention-banner">
          <div className="banner-content">
            <strong>
              <p>Save your spot!</p>
            </strong>
            <p className="banner-text">
              Next batch starts on <strong>September 4</strong>, 2023 for{" "}
              <strong>UiPath Advanced RPA developer.</strong>
            </p>
          </div>
        </div> */}
        <h1 className="title">
          Launch Your IT Career in 6 to 8 Months with Guaranteed Placement.
        </h1>
        <p className="title-message">
          We elevate talented individuals with little or no tech experience and
          help them to launch their careers in the IT industry.
        </p>
        <div className="top-tile-container">
          <div className="message-card">
            <div className="card-title">
              <div className="title-icon">
                <FontAwesomeIcon icon={faChalkboardUser} size="2x" />
              </div>
              <p className="card-title-text">Training</p>
            </div>
            <p className="card-text">
              We will train you to work on live projects within a team to
              simluate real-time work environment.
            </p>
          </div>

          <div className="message-card">
            <div className="card-title">
              <div className="title-icon">
                <FontAwesomeIcon icon={faHandshakeAngle} size="2x" />
              </div>
              <p className="card-title-text">Job Assistance</p>
            </div>
            <p className="card-text">
              We will help you prepare for interviews, provide 1-1 feedback and
              walk along until you land a job.
            </p>
          </div>

          <div className="message-card">
            <div className="card-title">
              <div className="title-icon">
                <FontAwesomeIcon icon={faCompass} size="2x" />
              </div>
              <p className="card-title-text">Coaching</p>
            </div>
            <p className="card-text">
              Get on-the-job coaching for 12 months, so you are set up for
              greater success.
            </p>
          </div>
        </div>
        <h2 className="find-career">Find a career that fits your goal</h2>
        <div className="cousre-tile-container">
          <div
            id="rpa-training"
            className="message-card course-card bg-sv-burgundy"
          >
            <div className="card-title course-title">
              <p className="card-title-text ">
                Robotic Process Automation (RPA)
              </p>
            </div>
            <p className="card-text course-text">
              Become an expert in UiPath for Robotic Process Automation. Learn
              various types of automation. Kickstart your career as an{" "}
              <span className="role-text">RPA developer</span>.
            </p>
            <a
              id="download_rpa"
              className="download_rpa"
              target="_blank"
              rel="noreferrer"
              href="https://fintek-point.s3.amazonaws.com/FINTEKPOINT+-+RPA+Development+S3.pdf"
            >
              Download Brochure
            </a>
          </div>

          <div
            id="web-development-training"
            className="message-card course-card bg-sv-burgundy"
          >
            <div className="card-title course-title">
              <p className="card-title-text">Full Stack Web Development</p>
            </div>
            <p className="card-text course-text">
              Get trained in Full stack web developement using HTML5, CSS,
              React, Redux, Node JS, AWS and other key tools. Launch your career
              as a&nbsp;
              <span className="role-text">Full Stack Engineer</span>.
            </p>

            <a
              id="download_webdev"
              className="download_webdev"
              target="_blank"
              rel="noreferrer"
              href="https://fintek-point.s3.amazonaws.com/FINTEKPOINT+-+Web+Development+S3.pdf"
            >
              Download Brochure
            </a>
          </div>
          <div className="message-card course-card bg-sv-burgundy">
            <div className="card-title course-title">
              <p className="card-title-text">DevOps & Cloud Migration</p>
            </div>
            <p className="card-text course-text">
              Get trained in AWS or Azure cloud. Build CI/CD pipeline using
              Docker, terraform and Jenkins. Launch your career as a&nbsp;
              <span className="role-text">
                DevOps and/or Cloud Migration Engineer
              </span>
              .
            </p>
            <a
              id="download_rpa"
              className="download_rpa"
              target="_blank"
              rel="noreferrer"
              href="https://fintek-point.s3.amazonaws.com/FINTEKPOINT+-+Azure+Cloud+Migration+S3.pdf"
            >
              Download Brochure
            </a>
          </div>

          <div className="message-card course-card bg-sv-burgundy">
            <div className="card-title course-title">
              <p className="card-title-text">Software Testing Automation</p>
            </div>
            <p className="card-text course-text">
              Get trained in software quality assurance(QA) and testing
              automation. Launch your career as a&nbsp;
              <span className="role-text">
                Software Development Engineer Test (SDET)
              </span>
              .
            </p>
            <a
              id="download_rpa"
              className="download_rpa"
              target="_blank"
              rel="noreferrer"
              href="https://fintek-point.s3.amazonaws.com/FINTEKPOINT+-+Software+Testing+S3.pdf"
            >
              Download Brochure
            </a>
          </div>
        </div>
        <div>
          {" "}
          <p className="admission">Admission process</p>
          <div className="admission-steps-container">
            <div className="message-card steps">
              <div className="card-title">
                <p className="circle-1">1</p>
              </div>
              <p className="card-text step-text">
                Complete online&nbsp;
                <Link to="/apply-now">
                  <u>application</u>
                </Link>
                <br />
                <br />
                Duration: 2-3 min
              </p>
            </div>

            <div className="message-card steps">
              <div className="card-title">
                <p className="circle-1">2</p>
              </div>
              <p className="card-text step-text">
                Complete an assesment with the program director
                <br />
                <br /> Duration: 30 min
              </p>
            </div>

            <div className="message-card steps">
              <div className="card-title">
                <p className="circle-1">3</p>
              </div>
              <p className="card-text step-text">
                Sign your enrollment commitment to save your spot
              </p>
            </div>
          </div>
        </div>
        <div className="what-it-takes-container">
          <div>
            <p className="what-it-takes">
              What it takes to qualify and succeed in our program?
            </p>
            <div className="what-it-takes-response">
              <p className="education">
                Minimum educational qualification: 3-Year bachelor's degree with
                <strong>&nbsp;mathematics.</strong>
              </p>
              <p>
                We look for foundational professional skills, a high level of
                motivation and a growth mindset.
              </p>
            </div>
          </div>

          <p className="what-it-takes-full-time">
            <strong>
              This is a full-time program that requires at least 40 hours/week
            </strong>
            .
          </p>
        </div>
        <div className="admission-process-container"></div>
        <h2 className="launch-career">Launch a new career in 3 steps</h2>
        <p className="title-message">
          We don’t stop at training you for a job. We are your partners in your
          long-term success. Take a look at what your tuition covers:
        </p>
        <div className="tution-coverage-container">
          <div className="message-card tution-refund">
            <div className="card-title tution-refund-title">
              <p>100% Refund Guarantee</p>
            </div>
            <p className="card-text tution-refund">
              We believe in our graduates and stand behind our program. If you
              work hard but don’t get the job, FINTEKPOINT will cover 100% of
              your fees.
            </p>
          </div>

          <div className="message-card training">
            <div className="card-title training-title">
              <p>What you get </p>
            </div>
            <p className="card-text training">
              Develop in-demand technical and professional skills to get ready
              for your new career through in-person, live online classes,
              mentorship and capstone projects.
            </p>
          </div>

          <div className="message-card job-search">
            <div className="card-title job-search-title">
              <p>6 months of job search support</p>
            </div>
            <p className="card-text job-search">
              Create a polished professional brand, master the interview, and
              get the job. Your dedicated Career Transition specialist will make
              the job hunt and launching your career easy. We’ll support you
              through your job search with personal guidance all the way through
              signing your offer.
            </p>
          </div>

          <div className="message-card on-job-coaching">
            <div className="card-title on-job-coaching-title">
              <p>1 year of on-the-job coaching</p>
            </div>
            <p className="card-text on-job-coaching">
              Your journey doesn’t stop when you get hired. Our certified
              professional coaches help you stand out at your new company by
              helping you create action plans to reach your goals, get promoted
              faster, and grow a successful career.
            </p>
          </div>
        </div>
      </div>
      <div className="risk-free-container">
        <h2 className="risk-free-title">Enroll now. Pay later</h2>
        <p className="title-message risk-free-message">
          Our flexible payment terms lets you pay <strong>60%</strong> of your
          tuition fees later. Simple, interest-free financing helps you focus on
          launching your career first. See details below.
        </p>

        <div className="risk-free-training-tile-container">
          <div className="message-card">
            <div className="card-title risk-free-card-title">
              <p>What is a Deferred Tuition fees Agreement (DTA)?</p>
            </div>
            <p className="card-text">
              A Deferred Tuition fees Agreement helps you focus on your training
              without worrying about your tuition fees. When you sign your DTA,
              you agree to pay&nbsp;
              <strong>₹30,000 (60%)</strong>
              of the tuition fees after you get hired in a qualifying role.
            </p>
          </div>

          <div className="message-card">
            <div className="card-title risk-free-card-title">
              <p>How much is the program?</p>
            </div>
            <p className="card-text">
              The total cost of this program is <strong>₹50,000</strong>. Your
              tuition fees includes&nbsp;8 weeks of full-time training, up to 6
              months of job search support and 6 months of on-the-job coaching.
            </p>
          </div>

          <div className="message-card">
            <div className="card-title risk-free-card-title">
              <p>When do I pay you back?</p>
            </div>
            <p className="card-text">
              Under DTA, you pay an inital tution fees of
              <strong> ₹20,000 (40%)</strong>
              &nbsp;and pay the remaining in 5 interest-free EMIs (
              <strong>₹6,000/month</strong>) after you get hired in a qualifying
              role.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
